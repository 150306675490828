.teams-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.teams-page h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.teams-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.team-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  text-decoration: none;
  color: #333;
  transition: transform 0.2s, box-shadow 0.2s;
}

.team-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.team-logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 10px;
}

team-card h2 {
  font-size: 16px;
  text-align: center;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.pagination button.active {
  background-color: #007bff;
  color: #fff;
}

@media (max-width: 768px) {
  .teams-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .team-card {
    padding: 15px;
  }

  .team-logo {
    width: 60px;
    height: 60px;
  }

  .team-card h2 {
    font-size: 14px;
  }
}

/* {date - time} - 2023-05-20 17:45 - Created Teams.css */
/* {date - time} - 2023-05-20 18:00 - Added responsive styles */
