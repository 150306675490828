/* Modernized Lineup CSS */
.lineup-container {
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/8/82/Soccer_Field_Transparant.svg");
  background-size: cover; /* Ensure the background covers the entire container */
  background-color: #333333;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  max-width: 1500px;
  margin: auto; /* Center the container on the page */
}

.team {
  border-radius: 15px;
  margin-bottom: 30px;
}

.team-info {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
  background-color: #ebebeb;
}

.team-logo {
  width: 150px;
  height: 150px;
  margin-right: 15px;
  justify-content: center;
  margin-left: 15px;
}

.team-name {
  font-size: 28px;
  font-weight: bold;
  color: #000000;
}

.lineup-formation {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, auto);
  grid-gap: 10px;
  justify-items: center; /* Ensure all rows are centered */
}

.lineup-row-1 {
  display: flex;
  justify-content: center;
  min-height: 50px;
  max-width: 450px;
}

.lineup-row-2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
}

.lineup-row-3,
.lineup-row-4 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.lineup-player {
  background-color: #ffffff;
  border-radius: 25px;
  overflow: hidden;
  transition: transform 0.3s ease;
  text-align: center;
  border-style: groove;
  border-width: 1px;
  border-color: #000000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added subtle shadow for depth */
  min-width: 300px;
  max-width: 450px;
}

.lineup-player:hover {
  transform: translateY(-5px);
}

.lineup-player-info {
  display: flex;
  flex-direction: row; /* Ensure content stacks vertically */
  align-items: center;
  text-align: center;
  margin-top: 10px;
  justify-content: space-around;
}

.player-image-container {
  position: relative;
  margin-bottom: 10px;
}

.player-image {
  border-radius: 50%;
  width: 120px; /* Increased image size */
  height: 120px; /* Increased image size */
}

.lineup-score-pill {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 24px; /* Increased font size for better emphasis */
  font-weight: bold;
  color: #000000;
  flex-shrink: 0;
  margin-right: 5px;
}

.lineup-player-name {
  font-size: 22px; /* Increased font size */
  margin: 10px 0 5px; /* Adjusted margin for spacing */
  font-weight: 600; /* Added emphasis to player names */
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(
    100% - 80px
  ); /* Ensure name doesn't encroach on the score-pill */
}

.lineup-review {
  background-color: #e3e3e3;
  padding: 15px;
  border-top: 1px solid #eee;
  min-height: 125px;
  text-align: center;
  place-content: center;
}

.lineup-review-text {
  font-size: 18px;
  line-height: 1.25;
  margin: 0;
  color: #000000; /* Darker text for better readability */
  font-weight: 600;
}

.lineup-row lineup-row-1 {
  min-height: 50px;
  max-width: 100px;
}

.h3 {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}

.username {
  font-weight: 600;
  color: #000000;
  text-align: center;
  align-items: center;
  line-height: 1;
  margin-right: 36px;
}

.score {
  font-weight: 600;
  color: #ff0000;
  margin: 10px;
}

.pundit-icon-lineup {
  width: 250px;
  height: auto;
  margin-left: 36px;
}

.game-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 600px) {
  .lineup-container {
    padding: 10px;
  }

  .team {
    padding: 15px;
  }

  .team-logo {
    width: 60px;
    height: 60px;
  }

  .team-name {
    font-size: 24px;
  }

  .lineup-player-name {
    font-size: 18px;
  }

  .lineup-review-text {
    font-size: 12px;
  }

  .lineup-player {
    padding: 15px; /* Adjusted padding for mobile */
  }

  .player-image {
    width: 100px; /* Adjusted image size for mobile */
    height: 100px;
  }

  .lineup-score-pill {
    width: 50px;
    height: 50px;
    font-size: 20px; /* Adjusted pill size for mobile */
    flex-shrink: 0;
  }
}

.App {
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
}
