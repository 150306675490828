.footer {
  background: linear-gradient(to right, #4a90e2, #5cb3ff);
  color: white;
  padding: 40px 20px;
  font-family: "Sono", sans-serif;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  flex: 1;
  margin: 0 15px;
}

.footer-section h3 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.footer-bottom {
  text-align: center;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 14px;
}

/* Improved responsive design for mobile devices */
@media (max-width: 768px) {
  .footer {
    padding: 20px 10px; /* Reduced padding */
  }

  .footer-container {
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 20px; /* Reduced margin */
  }

  .footer-bottom {
    margin-top: 20px; /* Reduced margin */
  }
}
