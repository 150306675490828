.player-rating-form {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: -webkit-center;
}

.player-rating-form select {
  width: 50%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

.form-title {
  margin-bottom: 20px;
}
.player-rating-form table {
  border-collapse: collapse;
  width: auto;
  text-align: center;
  margin-top: 10px;
}

.player-rating-form th,
.player-rating-form td {
  padding: 20px;
  border-bottom: 3px solid #ddd;
  flex-direction: row;
}

.player-position {
  text-align: left;
}

.player-rating-form th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.player-rating-form input[type="number"] {
  width: 60px;
  padding: 5px;
}

.player-rating-form button {
  display: block;
  width: 200px;
  margin: 20px auto;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.player-rating-form button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.form-header {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.player-photo {
  width: 100px;
  height: 100px;
  margin-right: 5px;
  border-radius: 50%;
}

.input,
textarea,
select {
  width: 60px;
  padding: 5px;
  text-align: center;
}

.green-pill {
  background-color: #22c55e;
}

.red-pill {
  background-color: #ef4444;
}

.orange-pill {
  background: #ffd700;
}

.white-pill {
  background-color: #ffffff;
}

.player-name {
  font-weight: bold;
  text-align: center;
  justify-content: center;
}

.ea-player-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-instructions {
  font-size: 18px;
  width: 35%;
}

.auth-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.auth-prompt-text {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 70%;
}

.ea-add-review-button {
  margin-bottom: 15px;
  background-color: #f79048;
  color: rgb(0, 0, 0);
  border: none;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
  .player-rating-form {
    padding: 10px;
  }

  .form-header {
    flex-direction: column;
  }

  .form-instructions {
    font-size: 14px;
    width: 75%;
  }

  .form-header select {
    width: 100%;
    margin-bottom: 10px;
  }

  .ea-player-ratings-table {
    font-size: 14px;
  }

  .ea-player-ratings-table th,
  .ea-player-ratings-table td {
    padding: 8px 5px;
  }

  .player-photo {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    border-radius: 50%;
  }

  .eaPlayerRatingForm .player-name {
    font-size: 0.9em;
    justify-content: left;
    display: flex;
    align-items: flex-end;
    text-align: left;
  }

  .player-position {
    font-size: 0.8em;
  }

  .country-flag {
    width: 12px;
    height: 12px;
    margin-right: 3px;
  }

  .rating-input {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }

  .eaPlayerRatingForm button[type="submit"] {
    width: 100%;
    margin: 15px;
  }

  .auth-prompt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .auth-prompt-text {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 70%;
  }

  .ea-add-review-button {
    margin-bottom: 15px;
    background-color: #f79048;
    color: rgb(0, 0, 0);
    border: none;
    font-size: 14px;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
}

@media (max-width: 480px) {
  .player-rating-form {
    padding: 5px;
  }

  .ea-player-ratings-table {
    font-size: 12px;
  }

  .ea-player-ratings-table th,
  .ea-player-ratings-table td {
    justify-content: left;
    line-height: 1;
    font-size: 16px;
    flex-shrink: 12px;
  }

  .player-photo {
    width: 75px;
    height: 75px;
    margin-right: 3px;
    border-radius: 50%;
  }

  eaPlayerRatingForm .player-name {
    font-size: 0.9em;
    justify-content: left;
    display: flex;
    align-items: flex-end;
    text-align: left;
  }

  .player-position {
    font-size: 0.7em;
  }

  .country-flag {
    width: 10px;
    height: 10px;
    margin-right: 2px;
  }

  .rating-input {
    width: 35px;
    height: 35px;
    font-size: 14px;
  }
  .player-rating-form input[type="number"] {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
}
