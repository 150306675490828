/* src/pages/team_ratings_style.css */
.team-ratings-page {
  padding: 16px;
  max-width: 1200px;
  margin: 0 auto;
}

.team-ratings-page h1 {
  text-align: center;
  margin-bottom: 16px;
  font-size: 28px;
  font-weight: bold;
}

.team-ratings-table {
  width: 75%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: auto;
}

.team-ratings-table th,
.team-ratings-table td {
  padding: 12px 16px;
}

.team-ratings-table th {
  font-weight: 600;
  color: #333;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
}

.team-ratings-table tr {
  border-bottom: 0px;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.team-player-name {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 90px;
  font-weight: bold;
}

.tr-rating-score {
  justify-content: center;
  text-align: -webkit-center;
}

.team-ratings-table tr:last-child td {
  border-bottom: none;
}

.team-ratings-table tr:hover {
  background-color: #f9f9f9;
}

.team-ratings-score-pill {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tr-team-logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 10px;
  flex-shrink: 0;
}

.competition-dropdown-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filters-container {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-bottom: 24px;
}

.season-dropdown,
.competition-dropdown {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: auto;
  background-color: #fff;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  flex-wrap: wrap;
  gap: 8px;
}

.pagination button {
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.pagination button:hover {
  background-color: #f0f0f0;
}

.pagination button.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.no-teams-message {
  text-align: center;
  font-size: 16px;
  color: #666;
  margin-top: 24px;
}

.header-row {
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
}

.tr-league-info {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.team-ratings-team-name {
  font-size: 20px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .team-ratings-page {
    padding: 12px;
  }

  .team-ratings-page h1 {
    font-size: 24px;
  }

  .filters-container {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 0px;
  }

  .team-ratings-table th,
  .team-ratings-table td {
    vertical-align: middle;
  }

  .team-ratings-team-name {
    font-size: 16px;
    flex-direction: row;
    display: flex;
    min-width: 120px;
  }

  .team-info {
    flex-direction: row;
    align-items: flex-start;
    min-width: 0;
    gap: 8px;
  }

  .tr-team-logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    flex-shrink: 0;
  }

  .team-ratings-score-pill {
    width: 48px;
    height: 48px;
    font-size: 14px;
  }

  .league-info,
  .ratings-count {
    font-size: 12px;
  }

  .tr-league-info {
    font-size: 12px;
    text-align: center;
    align-items: center;
    white-space: nowrap;
  }

  .team-ratings-table tr {
    border-bottom: 0px;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .team-player-name {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    min-height: 75px;
    font-weight: bold;
  }
}

/* {date - time} - 2023-05-20 17:15 -  Created team_ratings_style.css */
/* {date - time} - 2023-05-20 17:30 - Added responsive styles */
