/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hidden {
  display: none !important;
}

/* Header Styles */
.header {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  background: linear-gradient(to right, #4a90e2, #5cb3ff);
  color: white;
}

.header-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 800px;
}

.header-title {
  text-align: start;
  font-size: 30px;
  font-weight: bolder;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hamburger-menu {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

/* Improved Mobile Styles */
@media (max-width: 768px) {
  body {
    padding: 0;
  }

  .header {
    padding: 10px;
  }

  .nav {
    display: none;
    background-color: #5c5c5c;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    overflow-y: auto;
    z-index: 1000;
  }

  .nav.open {
    display: block;
  }

  .mobile-menu {
    list-style-type: none;
    padding: 0;
  }

  .header-title {
    font-size: 24px;
  }

  .mobile-menu a,
  .mobile-menu span,
  .mobile-menu button {
    text-decoration: none;
    font-size: 18px;
    display: block;
    padding: 10px 0;
  }

  .mobile-menu a,
  .mobile-menu span {
    color: white;
  }

  .mobile-menu button {
    padding: 8px;
  }

  .mobile-menu ul {
    list-style-type: none;
    padding-left: 20px;
  }

  .mobile-menu ul li {
    margin-bottom: 10px;
  }

  .hamburger-menu {
    display: block;
  }

  .header-right {
    flex-direction: row;
    align-items: end;
  }

  .register-button,
  .add-review-button {
    padding: 8px;
    font-size: 14px;
  }
}

/* Desktop Styles */
.desktop-nav {
  display: none;
  align-items: center;
}

.desktop-menu {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.desktop-menu > li {
  position: relative;
  margin-right: 20px;
}

.desktop-menu > li > a,
.desktop-menu > li > span {
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
}

.submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  padding: 10px;
  list-style-type: none;
  min-width: 200px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.desktop-menu > li:hover .submenu {
  display: block;
}

.submenu li {
  margin-bottom: 5px;
}

.submenu a {
  color: white;
  text-decoration: none;
  font-size: 14px;
  display: block;
  padding: 5px 0;
}

@media (min-width: 769px) {
  .hamburger-menu {
    display: none;
  }

  .desktop-nav {
    display: flex;
  }
}

/* User Profile Icon */
.user-profile-icon {
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 2px;
}

/* Buttons */
.add-review-button,
.register-button {
  background-color: #f79048;
  color: rgb(0, 0, 0);
  border: none;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-review-button:hover,
.register-button:hover {
  background-color: #e67836;
}

/* Additional Styles from the Original File */
.review-text {
  font-size: 16px;
  font-weight: bold;
}

.main {
  display: block;
  gap: 48px;
  justify-content: center;
}

@media (max-width: 900px) {
  .main {
    grid-template-columns: 1fr;
    height: auto;
  }
}

section {
  overflow: scroll;
  padding-bottom: 40px;
}

.fact-form {
  background-color: #b3b3b3;
  margin-bottom: 40px;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
}

.fact-form input,
.fact-form select {
  width: 220px;
  background-color: #ffffff;
  border: none;
  border-radius: 100px;
  padding: 16px;
  font-size: 18px;
  color: inherit;
  font-family: inherit;
}

.fact-form input::placeholder {
  color: #a8a29e;
}

.fact-form input:first-child {
  flex-grow: 1;
}

.fact-form span {
  font-weight: 600;
  font-size: 18px;
  margin-right: 18px;
}

@media (max-width: 1000px) {
  .fact-form {
    flex-direction: column;
    align-items: stretch;
  }

  .fact-form input,
  .fact-form select {
    width: auto;
  }
}

/* Search Bar */
.search-box input[type="text"] {
  padding: 5px;
  text-align: left;
}

.players-container {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  flex-direction: column;
  height: fit-content;
  max-width: 970px;
  padding: 10px;
}

.player {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 10px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.player img {
  flex-shrink: 0;
  margin-right: 10px;
}

.player_name {
  font-size: 1em;
  color: #007bff;
  font-weight: bold;
}

.player-info {
  display: flexbox;
  text-align: center;
  align-items: center;
}

.reviews-header {
  text-align: center;
  margin-top: 20px;
}

.footer {
  text-align: center;
  padding: 20px 0;
  background-color: #333;
  color: white;
}

.fact {
  font-size: 24px;
  line-height: 1.75;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  letter-spacing: -1px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-width: 970px;
  justify-content: center;
}

.review-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.score-pill {
  width: 56px;
  height: 56px;
  border-radius: 20px;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  flex-shrink: 0;
}

.review-details {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(209 213 219);
  padding: 12px;
}

.green-pill {
  background-color: #22c55e;
}

.red-pill {
  background-color: #ef4444;
}

.orange-pill {
  background: #ffd700;
}

.opposing_team_info {
  font-size: 14px;
  color: #555;
  font-style: italic;
  margin-bottom: 1px;
}

.player_stats {
  font-size: 20px;
  display: block;
}

/* Updated CSS for the slider */
.slider-score {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
}

.slider-score .score-pill {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
  color: #000;
}

/* Restoring Register Form Styles */
.auth-form-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 90%;
  z-index: 10000;
}

.auth-form-header {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.auth-form-header h2 {
  cursor: pointer;
  font-size: 18px;
  color: #333;
}

.auth-form-header h2.active {
  border-bottom: 2px solid #f79048;
  color: #f79048;
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.auth-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.auth-form button {
  background-color: #f79048;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.auth-form button:hover {
  background-color: #e67836;
}

/* Overlay styling */
.auth-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

/* Restoring Review Form Textarea Styles */
.fact-form textarea {
  width: 100%;
  min-height: 100px;
  max-height: 200px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 10px;
  padding: 16px;
  font-size: 16px;
  color: #333;
  font-family: "Cabin", sans-serif;
  resize: vertical;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.fact-form textarea::placeholder {
  color: #888;
  font-style: italic;
}

.fact-form textarea:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-color: #333;
}

.fact-form input[type="number"] {
  width: 100%;
  background-color: #f0f0f0;
  border: none;
  border-radius: 10px;
  padding: 16px;
  font-size: 16px;
  color: #333;
  font-family: "Cabin", sans-serif;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.mobile-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hamburger-menu {
  display: none;
}

.desktop-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 32px;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 768px) {
  .mobile-header {
    width: 100%;
    justify-content: flex-start;
  }

  .hamburger-menu {
    display: block;
  }

  .header-container {
    flex-wrap: wrap;
  }

  .desktop-nav {
    display: none;
  }
}

.pundit-icon {
  width: 150px;
  height: auto;
}
