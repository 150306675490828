/* src/components/EAPlayerRatingTable.css */
.ea-player-rating-table {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.ea-player-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.team-select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

th,
td {
  padding: 12px;
  border-bottom: 1px solid #dee2e6;
  text-align: center;
  vertical-align: middle;
}

td {
  text-align: center;
  align-items: center;
}

img {
  width: 100px;
  height: 100px;
}

.ea-player-name {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
  font-size: 20px;
}

th {
  background-color: #e9ecef;
  font-weight: bold;
}

th.rating-column,
td.rating-column {
  width: 120px;
}

.rating {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 60px;
  height: 60px;
  font-size: 20px;
  line-height: 1;
  text-align: center;
}

.ea-player-rating-table-title {
  text-align: center;
  margin-bottom: 10px;
}

.rating.green {
  background-color: #28a745;
  color: black;
}

.rating.yellow {
  background-color: #ffc107;
  color: black;
}

.rating.orange {
  background-color: #fd7e14;
  color: black;
}

/* {date - time} - 2023-11-21 14:45 - Added responsive styles */
@media (max-width: 768px) {
  th.rating-column,
  td.rating-column {
    width: 50px;
  }

  .rating {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  th,
  td {
    padding: 8px;
    border-bottom: 1px solid #dee2e6;
    text-align: center;
    vertical-align: middle;
  }

  .ea-player-name {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    text-align: left;
    flex-direction: row;
  }
  .position {
    font-size: 12px;
  }
  .header-row {
    font-size: 14px;
    font-weight: bold;
  }
  body {
    line-height: 1.2;
  }
  .ea-player-photo {
    width: 60px; /* Adjust the size as needed */
    height: 60px; /* Adjust the size as needed */
    border-radius: 50%;
  }
}
