/* src/styles/global.css */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

:root {
  --primary-color: #3498db;
  --secondary-color: #2980b9;
  --background-color: #f5f7fa;
  --text-color: #333;
  --light-text-color: #555;
  --border-color: #ddd;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 1.4;
  color: var(--text-color);
  background-color: var(--background-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

button {
  font-family: "Inter", sans-serif;
}

input,
textarea,
select {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}

.vote-buttons {
  margin-left: auto;
  flex-shrink: 0;
  display: flex;
  gap: 8px;
  padding-right: 12px;
}

.vote-buttons button {
  border: none;
  background-color: #f79048;
  padding: 6px 12px;
  border-radius: 100px;
  color: inherit;
  font-family: inherit;
  font-weight: bolder;
  cursor: pointer;
  transition: 0.3s;
}

.vote-buttons button:hover {
  background-color: #292524;
}
/* src/styles/AuthForm.css */
/* Forgot Password modal styling {2024-09-20 - 13:08} */
.forgot-password-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.forgot-password-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
}

.auth-error {
  color: red;
  margin-top: 10px;
}

.privacy-container {
  padding: 20px;
  max-width: 800px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  line-height: 2;
}

.terms-container {
  padding: 20px;
  max-width: 800px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  line-height: 2;
}

@media (max-width: 768px) {
  .vote-buttons {
    padding-right: 0px;
  }
  .review-author {
    padding-left: 0px;
  }
}
