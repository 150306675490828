.team-profile {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.team-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 20px;
  background-color: #ebebeb;
  border-radius: 8px;
}

.tp-team-info {
  align-items: center;
  display: block;
  text-align: center;
}

.tp-team-logo {
  width: 80px;
  height: 80px;
}

.team-name {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.team-competition {
  font-size: 16px;
  color: #666;
}

.team-rating {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-rating span {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.tp-score-pill {
  width: 60px;
  height: 60px;
  border-radius: 24px;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  margin-right: 0px;
}

.matches-section,
.players-section {
  margin-top: 0px;
  overflow: hidden;
  padding-bottom: 10px;
}

.competition-group {
  margin-bottom: 0px;
}

.tp-match-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 10px;
}

.match-info {
  display: flex;
  flex-direction: column;
  line-height: 1.25;
}

.match-date {
  color: #666;
}

.match-teams {
  font-weight: bold;
  margin: 5px 0;
}

.match-score {
  font-size: 20px;
  font-weight: bold;
  color: #e74c3c;
}

.match-actions {
  display: flex;
  gap: 10px;
}

.btn {
  padding: 8px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
}

.players-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.player-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.tp-player-headshot {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 10px;
}

.player-info {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.player-info h4 {
  margin: 0px;
}

section {
  overflow: hidden;
  padding-bottom: 10px;
}

.tp-section-title {
  text-align: center;
  margin-bottom: 5px;
}

.tp-team-logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .team-header {
    text-align: center;
  }

  .team-info {
    flex-direction: column;
  }

  .team-logo {
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .team-rating {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .tp-match-card {
    flex-direction: column;
  }

  .match-info {
    margin-bottom: 15px;
    text-align: center;
    gap: 4px;
  }

  .players-grid {
    flex-direction: column;
    display: flex;
    gap: 5px;
  }
  .player-card {
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
  }

  .matches-section,
  .players-section {
    margin-top: 10px;
  }

  .tp-player-headshot {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-right: 1px solid #e0e0e0;
    margin-bottom: 0px;
    border-radius: 50%;
  }
  .body {
    line-height: 1.25;
  }
  .team-header {
    margin-bottom: 10px;
  }
  .player-info {
    justify-content: center;
    align-items: center;
    padding: 0px;
    display: flex;
    flex-direction: row;
    text-align: center;
  }
  .tp-score-pill {
    width: 60px;
    height: 60px;
    border-radius: 24px;
    font-size: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    margin-right: 8px;
  }
  .player-info h4 {
    flex-shrink: 1;
    display: flex;
  }
}
